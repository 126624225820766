import React from 'react'
import { AboutWrap, TitleWrap } from './styles'

const About = () => {
  return (
    <AboutWrap>
      <div className='container'>
        <TitleWrap>
          <h2>회사소개</h2>
        </TitleWrap>
        <div className='img-wrap'>
          <img src='/image/about-img.png' alt='회사소개 이미지' />
        </div>
        <div className='text-wrap'>
          <p>
            <span>엠케이몰상품권</span>은 쿠폰스토리의 자회사로 <span>상품권 대량 매입의 표준</span>을 제공하며<br/>
            <b>24시간 편리하고 안전하게 구매 가능합니다</b>
          </p>
        </div>
        <div className='info-wrap'>
          <div className='info'>
            <dl>
              <dt>업종</dt>
              <dd>상품권유통 / 서비스</dd>
            </dl>
            <dl>
              <dt>주소</dt>
              <dd>{process.env.REACT_APP_ADDRESS}</dd>
            </dl>
            <dl>
              <dt>전화번호</dt>
              <dd>{process.env.REACT_APP_PHONE}</dd>
            </dl>
            <dl>
              <dt>휴일</dt>
              <dd>연중 무휴(24시간 영업)</dd>
            </dl>
          </div>
          <div className='map'>
            <iframe src={process.env.REACT_APP_COMPANY_GOOGLE_ADDRESS}/>
          </div>
        </div>
      </div>
    </AboutWrap>
  )
}

export default About
