import styled from "styled-components";

export const InquiryWrap = styled.div`
  padding: 9rem 0 15rem;
  .btn-wrap {
    margin-top: 1.6rem;
    a {
      width: 14rem;
      height: 4.4rem;
      border-radius: 2.2rem;
      background-color: #1da049;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
      img {
        margin-right: 1rem;
      }
      span {
        font-size: 1.4rem;
        font-weight: 400;
        color: #fff;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 8rem 0;
    .btn-wrap {
      a {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 500px) {
    padding: 6rem 0;
    .btn-wrap {
      a {
        height: 4rem;
        border-radius: 2rem;
        img {
          margin-right: 0.5rem;
        }
      }
    }
  }
`

export const TitleWrap = styled.div`
  padding-left: 3.2rem;
  margin-bottom: 4.8rem;
  h2 {
    font-size: 2.8rem;
    font-weight: 500;
    color: #222;
  }
  @media screen and (max-width: 768px) {
    padding-left: 0;
    margin-bottom: 4.5rem;
    h2 {
      font-size: 2.6rem;
      text-align: center;
    }
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 4rem;
    h2 {
      font-size: 2.4rem;
    }
  }
`

export const InquiryWriteWrap = styled.div`
  padding: 9rem 0 15rem;
  .write-wrap {
    border-top: 0.1rem solid #dfdfdf;
    border-bottom: 0.1rem solid #dfdfdf;
    padding: 2rem 0;
    .info-wrap {
      display: flex;
      gap: 2rem;
      &:not(:last-child) {
        margin-bottom: 1.6rem;
      }
      .w50 {
        flex: 1;
      }
      .center {
        display: flex;
        align-items: center;
      }
    }
  }
  .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    button {
      width: 8rem;
      height: 4.4rem;
      border-radius: 0.4rem;
      font-size: 1.4rem;
      color: #fff;
      box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
      &:first-child {
        background-color: #9e9e9e;
      }
      &:last-child {
        background-color: #1da049;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 8rem 0;
    .write-wrap {
      .info-wrap {
        flex-wrap: wrap;
        &.mo-db {
          display: block;
          > div {
            &:not(:first-child) {
              margin-top: 2rem;
            }
          }
        }
        .center {
          flex: unset;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    padding: 6rem 0;
    .btn-wrap {
      button {
        height: 4rem;
      }
    }
  }
`
