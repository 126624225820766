import styled from "styled-components";

export const ShopItemWrap = styled.li`
  width: 25%;
  height: auto;
  padding: 0 1rem;
  &:nth-child(n + 5) {
    margin-top: 2rem;
  }
  a {
    display: block;
    border: 0.1rem solid #dfdfdf;
    border-radius: 1.5rem;
    overflow: hidden;
    transition: all ease 0.3s;
    &:hover {
      border-color: #1da049;
    }
    .figure {
      width: 100%;
      padding-top: 100%;
    }
    .info {
      padding: 2rem 1.5rem;
      .title {
        font-size: 2rem;
        font-weight: 500;
      }
      .text{
        font-size: 1.6rem;
        color: #1da049;
        margin-top: 1rem;
      }
    }
  }
  @media screen and (max-width: 1048px) {
    width: calc(100% / 3);
    &:nth-child(n + 4) {
      margin-top: 2rem;
    }
  }
  @media screen and (max-width: 768px) {
    width: 50%;
    &:nth-child(n + 3) {
      margin-top: 2rem;
    }
  }
  @media screen and (max-width: 500px) {
    .info {
      padding: 1.5rem;
      .title {
        font-size: 1.6rem;
      }
      .text{
        font-size: 1.4rem;
      }
    }
  }
`
