import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const SideMenuWrap = styled.div`
  width: 26rem;
  height: auto;
  .title {
    background-color: #1da049;
    padding: 1.6rem;
    span {
      font-size: 2rem;
      font-weight: 500;
      color: #fff;
    }
  }
  ul {
    border: 0.1rem solid #dfdfdf;
    li {
      &:not(:last-child) {
        border-bottom: 0.1rem solid #dfdfdf;
      }
      a {
        display: block;
        width: 100%;
        height: 4rem;
        padding: 0 1.6rem;
        font-size: 1.4rem;
        line-height: 4rem;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.87);
      }
      &.active {
        a {
          background-color: #f5f5f5;
        }
      }
    }
  }
  @media screen and (max-width: 1240px) {
    display: none;
  }
`

const SideMenu = () => {

  const location = useLocation()

  const menuList = [
    {
      title: '회원정보수정',
      path: '/my/profile'
    },
    {
      title: '상품권 구매내역',
      path: '/my/order?page=0'
    }
  ]

  return (
    <SideMenuWrap>
      <div className="title">
        <span>마이페이지</span>
      </div>
      <ul>
        {
          menuList.map((item, index) => {
            return (
              <li 
                key={index}
                className={item.path.split('?')[0] === location.pathname ? 'active' : ''}
              >
                <Link to={item.path}>
                  {item.title}
                </Link>
              </li>
            )
          })
        }
      </ul>
    </SideMenuWrap>
  )
}

export default SideMenu
