import React from 'react'
import { ContactWrap, TitleWrap } from './styles'

const Contact = () => {
  return (
    <ContactWrap>
      <div className="container">
        <TitleWrap>
          <h2>대량거래/체인점문의</h2>
        </TitleWrap>
        <div className="img-wrap">
          <img src="/image/inquiry-img.png" alt="악수하는 이미지" />
        </div>
        <p><span>대량거래</span> 또는 <span>사업</span>에 관심이 있는 분은 <strong>언제든지 연락주세요</strong></p>
        <div className="img-wrap">
          <img src="/image/inquiry-img2.png" alt="대량거래/체인점 문의 관련 이미지" />
        </div>
        <span className='info-text'>* 연락처 성함 필수이며 담당자 검토 후 24시간 이내 연락 드리겠습니다</span>
        <div className="info-wrap">
          <p>엠케이몰</p>
          <div className="info">
            <h4>엠케이몰상품권</h4>
            <dl>
              <dt>대표번호</dt>
              <dd>{process.env.REACT_APP_PHONE}</dd>
            </dl>
            <dl className='mb0'>
              <dt>이메일</dt>
              <dd>{process.env.REACT_APP_EMAIL}</dd>
            </dl>
          </div>
        </div>
      </div>
    </ContactWrap>
  )
}

export default Contact
