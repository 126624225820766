import React, {useState} from 'react'
import { LoginWrap } from './styles'
import InputBox from '../../components/inputBox'
import Button from '../../module/button'
import CheckLabel from '../../components/checkLabel'
import { Link } from 'react-router-dom'
import {AuthAction} from '../../store/action'
import { useSetRecoilState } from 'recoil'
import { alertAtom } from '../../store/recoil/alert'

const Login = () => {

  const setAlert = useSetRecoilState(alertAtom)

  const {authLogin} = AuthAction()
  const [state, setState] = useState({
    userId: '',
    password: ''
  })

  const handleSubmit = (e: any) => {
    if (e) {
      e.preventDefault()
    }
    const {userId, password} = state

    if (!userId) {
      setAlert({
        title: '로그인',
        type: 'alert',
        message: '아이디를 입력해주세요.',
        isShow: true
      })
    } else if (!password) {
      setAlert({
        title: '로그인',
        type: 'alert',
        message: '비밀번호를 입력해주세요.',
        isShow: true
      })
    } else {
      authLogin(userId, password)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const {userId, password} = state

      if (!userId) {
        setAlert({
          title: '로그인',
          type: 'alert',
          message: '아이디를 입력해주세요.',
          isShow: true
        })
      } else if (!password) {
        setAlert({
          title: '로그인',
          type: 'alert',
          message: '비밀번호를 입력해주세요.',
          isShow: true
        })
      } else {
        authLogin(userId, password)
      }
    }
  };

  return (
    <LoginWrap>
      <div className='login-box'>
        <div className='title-wrap'>
          <h2>Login</h2>
        </div>
        <div className='info-wrap'>
          <InputBox
            title='아이디'
            value={state.userId ? state.userId : ''}
            handleChange={(value: any) => setState({
              ...state,
              userId: value
            })}
            handleKeyDown={handleKeyDown}
          />
          <InputBox
            title='비밀번호'
            value={state.password ? state.password : ''}
            marginTop={16}
            password={true}
            handleChange={(value: any) => setState({
              ...state,
              password: value
            })}
            handleKeyDown={handleKeyDown}
          />
          <Button
            text='로그인'
            borderColor='#1da049'
            backgroundColor='#1da049'
            textColor='#fff'
            marginTop={16}
            boxShadow='0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)'
            handleClick={handleSubmit}
          />
          <Button
            text='회원가입'
            link='/join'
            borderColor='rgba(0,0,0,.87)'
            backgroundColor='#fff'
            textColor='rgba(0,0,0,.87)'
            hoverColor='rgba(0,0,0,.87)'
            marginTop={16}
          />
        </div>
        <div className="btn-wrap">
          <CheckLabel
            text='아이디 저장'
            labelId='saveId'
          />
          <Link to={'/find/user'}>
            아이디/비밀번호 찾기
          </Link>
        </div>
      </div>
    </LoginWrap>
  )
}

export default Login
