import React, {useState, useRef, useEffect} from 'react'
import {Form, Step02Wrap} from './styles'
import InputBox from '../../components/inputBox'
import Button from '../../module/button'
import CheckLabel from '../../components/checkLabel'
import {JoinStepProps} from './types'
import Select from '../../module/select'
import Address from '../../components/address'

import {AuthAction} from '../../store/action'
import {optionList} from './dummy'
import { useSetRecoilState } from 'recoil'
import { alertAtom } from '../../store/recoil/alert'
import moment from "moment";

const Step02 = ({active, handleStep}: JoinStepProps) => {

  // Alert
  const setAlert = useSetRecoilState(alertAtom)
  const formRef = useRef<HTMLFormElement>(null)
  const {authJoin} = AuthAction()
  const [selectValue, setSelectValue] = useState('')
  const [state, setState] = useState({
    userId: '',
    name: '',
    email0: '',
    email1: '',
    phone0: '',
    phone1: '',
    phone2: '',
    password: '',
    confirmPassword: '',
    post: '',
    address0: '',
    address1: '',
    agree: true,
    birth: ''
  })

  // 비밀번호 조건 확인
  const checkPasswordValidity = (password: string) => {
    const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/
    return regex.test(password)
  }

  const handleSubmit = async () => {
    if (!state.userId) {
      setAlert({
        title: '정보확인',
        type: 'alert',
        message: '아이디를 입력해주세요.',
        isShow: true
      })
    } else if (state.password !== state.confirmPassword) {
      setAlert({
        title: '정보확인',
        type: 'alert',
        message: '비밀번호가 일치하지 않습니다.',
        isShow: true
      })
    } else if (!checkPasswordValidity(state.password) || state.password === state.userId) {
      setAlert({
        title: '정보확인',
        type: 'alert',
        message: '비밀번호는 회원 아이디와 동일하지 않아야 하며 영어+숫자+특수문자 혼합 문자로 최소 8~20자리입니다.',
        isShow: true
      })
    } else if (!state.name) {
      setAlert({
        title: '정보확인',
        type: 'alert',
        message: '본인인증을 해주세요.',
        isShow: true
      })
    } else if (!state.phone0 || !state.phone1 || !state.phone2) {
      setAlert({
        title: '정보확인',
        type: 'alert',
        message: '본인인증을 해주세요.',
        isShow: true
      })
    } else if (!state.email0 || !state.email1) {
      setAlert({
        title: '정보확인',
        type: 'alert',
        message: '이메일을 입력해주세요.',
        isShow: true
      })
    } else if (!state.post || !state.address0 || !state.address1) {
      setAlert({
        title: '정보확인',
        type: 'alert',
        message: '주소를 입력해주세요.',
        isShow: true
      })
    } else {
      authJoin(state)
      .then((res) => {
        if(res?.status === 200) {
          handleStep()
        }
      })
    }
  }

  const handleChangeSelect = (v: string) => {
    setState({
      ...state,
      email1: v
    })
    setSelectValue(v)
  }

  // 이메일 선택 상태 체크
  const handleChangeEmail = (v: string) => {
    if (!selectValue) {
      setState({
        ...state,
        email1: v
      })
    }
  }

  // 우편주소 모달
  const [addressModal, setAddressModal] = useState(false)
  const handleAddressModal = (v: boolean) => {
    setAddressModal(v)
  }
  const handleAddress = (code: string, address: string) => {
    setState({
      ...state,
      post: code,
      address0: address
    })
  }

  const cert = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}cert/make_hash`, "_blank", "width=400,height=700")
  }

  useEffect(() => {
    const handleMessage = (event: any) => {

      if (event.data.res_cd === '0000') {
        console.log(event.data)
        setState({
          ...state,
          name: event?.data.user_name,
          phone0: event?.data.phone_no.slice(0, 3),
          phone1: event?.data.phone_no.slice(3, 7),
          phone2: event?.data.phone_no.slice(7, 11),
          birth: moment(event?.data.birth_day).format('YYMMDD')
        })
      }
    }
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [state])


  const handleChangePhone = (k: string, v: any) => {
    if (!isNaN(v)) {
      setState({
        ...state,
        [k]: v
      })
    }
  }

  return (
    <>
    <Form onSubmit={handleSubmit}>
      <Step02Wrap
        className={active ? 'active' : ''}
      >
        <div className="info-box w50">
          <InputBox
            title="아이디"
            value={state.userId ? state.userId : ''}
            handleChange={(value: string) => setState({
              ...state,
              userId: value
            })}
          />
        </div>
        <div className="info-box mo-db">
          <InputBox
            title="비밀번호"
            value={state.password ? state.password : ''}
            handleChange={(value: string) => setState({
              ...state,
              password: value
            })}
            password={true}
          />
          <InputBox
            title="비밀번호확인"
            value={state.confirmPassword ? state.confirmPassword : ''}
            handleChange={(value: string) => setState({
              ...state,
              confirmPassword: value
            })}
            password={true}
          />
        </div>
        <div className="info-box">
          <Button
            text="본인인증"
            textColor="#1da049"
            borderColor="#1da049"
            backgroundColor="#fff"
            hoverColor="#1da049"
            icon="/image/user-check-icon.png"
            handleClick={() => cert()}
          />
        </div>
        <div className="info-box w50">
          <InputBox
            title="이름"
            value={state.name ? state.name : ''}
            handleChange={(value: string) => setState({
              ...state,
              name: value
            })}
            disabled={true}
          />
        </div>
        <div className="info-box w75">
          <InputBox
            title="연락처"
            value={state.phone0 ? state.phone0 : ''}
            handleChange={(value: string) => handleChangePhone('phone0', value)}
            maxLength={3}
            disabled={true}
          />
          <span className="line">-</span>
          <InputBox
            title=""
            value={state.phone1 ? state.phone1 : ''}
            handleChange={(value: string) => handleChangePhone('phone1', value)}
            maxLength={4}
            disabled={true}
          />
          <span className="line">-</span>
          <InputBox
            title=""
            value={state.phone2 ? state.phone2 : ''}
            handleChange={(value: string) => handleChangePhone('phone2', value)}
            maxLength={4}
            disabled={true}
          />
        </div>
        <div className="info-box w75 email">
          <InputBox
            title="이메일"
            value={state.email0 ? state.email0 : ''}
            handleChange={(value: string) => setState({
              ...state,
              email0: value
            })}
          />
          <span className="email">@</span>
          <InputBox
            title=""
            value={state.email1 ? state.email1 : ''}
            handleChange={(value: string) => handleChangeEmail(value)}
          />
          <Select
            optionList={optionList}
            value={selectValue}
            handleChange={handleChangeSelect}
            flex={1}
          />
        </div>
        <div className="info-box w50">
          <InputBox
            title="우편번호"
            value={state.post ? state.post : ''}
            handleChange={(value: string) => setState({
              ...state,
              post: value
            })}
            handleClick={() => handleAddressModal(true)}
          />
        </div>
        <div className="info-box w50 mo-db">
          <InputBox
            title="기본주소"
            value={state.address0 ? state.address0 : ''}
            handleChange={(value: string) => setState({
              ...state,
              address0: value
            })}
            handleClick={() => handleAddressModal(true)}
          />
          <InputBox
            title="상세주소"
            value={state.address1 ? state.address1 : ''}
            handleChange={(value: string) => setState({
              ...state,
              address1: value
            })}
          />
        </div>
        <div className="info-box">
          <CheckLabel
            text="SMS 수신동의"
            labelId="sns-agree"
            checked={state.agree}
            handleChange={() => setState({
              ...state,
              agree: !state.agree
            })}
          />
        </div>
        <div className="info-box">
          <Button
            text="회원가입"
            textColor="#1da049"
            borderColor="#1da049"
            backgroundColor="#fff"
            hoverColor="#1da049"
            handleClick={handleSubmit}
          />
        </div>
      </Step02Wrap>
      <Address
        modal={addressModal}
        handleModal={handleAddressModal}
        handleChange={handleAddress}
      />
    </Form>
    <form ref={formRef}>

    </form>
    </>
  )
}

export default Step02
