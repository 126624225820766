import React, { useEffect, useState } from 'react'
import Banner from '../../components/banner'
import Tab from '../../components/tab'
import {cardList, sliderList, tabList } from './dummy'
import Card from '../../components/card'
import { Section, Title } from './styles'
import Slider from '../../components/slider'
import { Link, useNavigate } from 'react-router-dom'
import { CategoryAction, NoticeAction } from '../../store/action'
import { useRecoilValue } from 'recoil'
import { noticeListAtom } from '../../store/recoil/notice'
import moment from 'moment'
import Popup from '../../components/popup'
import { UserPopupAction } from '../../store/action/userPopup'
import { PopupInterface } from '../../components/popup/types'
import MainPay from "../../module/mainPay";
import { UserProductAction } from '../../store/action/userProduct'
import {authAtom} from "../../store/recoil/auth";
import wingPay from "../../module/wingPay";

const Home = () => {

  const navigate = useNavigate()

  // Product Action
  const { getProductList } = UserProductAction()


  // Category Action
  const { getHotdealId } = CategoryAction()

  // 핫딜 리스트
  const [hotList, setHotList] = useState([])

  // 카테고리 텝메뉴
  const [activeTab, setActiveTab] = useState(0)
  const handleChangeTab = (i: number) => {
    setActiveTab(i)
  }

  // 공지사항 리스트 가져오기
  const { getNoticeList } = NoticeAction()
  useEffect(() => {
    getNoticeList({
      page: 0,
      size: 5,
      type: 'notice',
      keyword: '',
      searchType: ''
    })
  }, [])
  const noticeList = useRecoilValue(noticeListAtom)

  // Popup
  const { getPopupList } = UserPopupAction()
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10
  })
  const [popupList, setPopupList] = useState<PopupInterface[]>([])


  useEffect(() => {
    // 팝업 리스트
    getPopupList(pagination)
    .then((res) => {
      setPopupList(res.data)
    })

    // 핫딜 상품 리스트 가져오기
    getHotdealId()
    .then((res) => {
      getProductList(res.payload)
      .then((res) => {
        setHotList(res.data)
      })
    })
  }, [])


  return (
    <>
      <Banner/>
      <Section className='p70'>
        <div className='container'>
          <Title>
            <h2><b>핫딜상품</b> 엠케이몰상품권이 준비한 핫!딜!</h2>
          </Title>
          <Slider 
            list={hotList}
          />
        </div>
      </Section>
      <Section className='pb70'>
        <div className='container'>
          <Tab 
            tabList={tabList} 
            handleChange={handleChangeTab}
          />
          <div className='cardListWrap'>
            <Card
              list={cardList}
            />
          </div>
        </div>
      </Section>
      <Section className='board-wrap p70'>
        <div className='container'>
          <div className='box'>
            <div className='title-wrap'>
              <h3>공지사항</h3>
              <button 
                type='button'
                onClick={() => navigate('/notice?page=0')}
              >
                <i></i>
              </button>
            </div>
            <div className='notice'>
              <ul>
                {
                  noticeList?.data.length > 0 ? noticeList.data.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link to={`/notice/detail/${item.id}`}>
                          <div className='title'>{item.title}</div>
                          <div className='date'>{moment(item.created_at).format('YYYY-MM-DD')}</div>
                        </Link>
                      </li>
                    )
                  }) : <li className='empty'>등록된 게시글이 없습니다.</li>
                }
              </ul>
            </div>
          </div>
          <div className='box'>
            <div className='title-wrap'>
              <h3>고객센터</h3>
              <button 
                type='button'
                onClick={() => navigate('/inquiry?page=0')}
              >
                <i></i>
              </button>
            </div>
            <div className='sub-title'>
              <img src="/image/comment-icon.png" alt="텍스트 아이콘" />
              <h4>고객센터</h4>
            </div>
            <dl>
              <dt>Tel</dt>
              <dd><strong>{process.env.REACT_APP_PHONE}</strong></dd>
            </dl>
            <dl>
              <dt>E-mail</dt>
              <dd>{process.env.REACT_APP_EMAIL}</dd>
            </dl>
            <p>운영시간 : 24시간 연중 무휴</p>
          </div>
        </div>
      </Section>
      {
        popupList.filter((item) => item.display === 1).map((item, index) => {

          const today = new Date()
          const fromDate = new Date(item.from)
          const toDate = new Date(item.to)
          toDate.setHours(23, 59, 59, 999)

          const isTodayBetweenFromAndTo = today >= fromDate && today <= toDate

          if (isTodayBetweenFromAndTo) {
            return (
              <Popup key={index} item={item} />
            )
          }
        })
      }
    </>
  )
}

export default Home;
