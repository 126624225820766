import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {useRecoilValue, useSetRecoilState} from 'recoil'

import {AuthAction} from '../../../store/action'
import {authAtom} from '../../../store/recoil/auth'
import {alertAtom} from '../../../store/recoil/alert'
import Svg from '../../module/svg'
import Button from '../../module/button'

const Container = styled.div`
  display: flex;
  min-height: 6.7rem;
  align-items: center;
  justify-content: center;
  border-bottom: .1rem solid #efebef;

  .nav-btn {
    display: none;
  }

  @media screen and (max-width: 500px) {
    .logo {
      display: none;
    }
    .nav-btn {
      display: block;
      span {
        display: block;
        width: 20px;
        height: 2px;
        background-color: #fff;
        border-radius: 1px;
        &:nth-child(2) {
          margin: 5px 0;
        }
      }
    }
  }
`

const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  background: #333;
`

const Logo = styled.div`
  width: 16rem;
  height: 6.7rem;
  font-size: 2.6rem;
  font-weight: 600;
  color: #1da049;
  padding-top: 1.5rem;
  cursor: pointer
`

const User = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: #ffffff;
`

const UserName = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`

const UserEmail = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
`

const UserImage = styled.img.attrs((props) => ({src: props.src,}))`
  border-radius: 3rem;
  width: 3rem;
  margin-right: 1rem;
`

const SubLayer = styled.div`
  position: absolute;
  left: 0;
  bottom: -4rem;
  width: 100%;
  background: #ffffff;
  border: .1rem solid #efebef;
  border-radius: .5rem;
  padding: 1rem;
  text-align: center;
  z-index: 9999;

  button {
    width: 100%;
    font-size: 1.4rem;
  }
`

const Nav = styled.div`
  display: flex;
`

const dummy = {
  user: {
    image: '/image/avatar.jpg'
  }
}

const Index = ({handleClickNav}: any) => {

  const auth = useRecoilValue(authAtom)
  const {authAdminLogout} = AuthAction()
  const [isHovering, setIsHovering] = useState(false)
  const setAlert = useSetRecoilState(alertAtom)

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }
  return (
    <Container>
      <Header>
        <button 
          type="button" 
          className='nav-btn'
          onClick={handleClickNav}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <Link to={'/admin'} className='logo'>
          <Logo>엠케이몰</Logo>
        </Link>
        <Nav>
          <Button
            size={'md'}
            margin={'1rem 2.5rem 1rem 0'}
            radius={'0'}
            bgcolor={'transparent'}
            color={'#ffffff'}
            disabled={false}
            onClick={() => window.open('/', '_blank')}
          >
            <Svg
              icon={'home'}
              width={31}
              height={31}
              fill={'#ffffff'}
              color={'#ffffff'}
            />
          </Button>
          <User
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <UserImage
              src={dummy.user.image}
            />
            <UserInfo>
              <UserName>
                {auth.name}
              </UserName>
              <UserEmail>
                Administrator
              </UserEmail>
            </UserInfo>
            {
              isHovering &&
              <SubLayer>
                <button
                  onClick={() => {
                    setAlert({
                      title: '',
                      type: 'confirm',
                      message: '로그아웃하시겠습니까?',
                      isShow: true,
                      action: {
                        method: authAdminLogout,
                        params: {token: auth.token}
                      }
                    })
                  }}
                >
                  로그아웃
                </button>
              </SubLayer>
            }
          </User>
        </Nav>
      </Header>
    </Container>
  )
};

export default Index
