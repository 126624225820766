import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { authAtom } from '../../store/recoil/auth'
import { CategoryAction } from '../../store/action/category'
import { CategoryInterface } from './types'
import { HeaderStyle } from './styles'
import { AuthAction } from '../../store/action'
import { alertAtom } from '../../store/recoil/alert'

interface MenuListInterface {
  title: string
  link?: string
  handleClick?: () => void
}

const Header = () => {

  // 유저 정보
  const auth = useRecoilValue(authAtom)

  // 로그아웃
  const {authLogout} = AuthAction()
  const handleLogout = () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      authLogout(auth.token)
    }
  }

  // GnbList
  const gnbList = [
    {
      iconPath: '/image/gnb-google.png',
      title: '구글기프트카드',
      link: '/shop?category=google'
    },
    {
      iconPath: '/image/gnb-culture.png',
      title: '컬쳐랜드',
      link: '/shop?category=culture'
    },
    {
      iconPath: '/image/gnb-happy.png',
      title: '해피머니',
      link: '/shop?category=happy'
    },
    {
      iconPath: '/image/gnb-bizgift.png',
      title: 'e-bizGIFT',
      link: '/shop?category=bizgift'
    },
    {
      iconPath: '/image/gnb-game.png',
      title: '게임상점',
      link: '/shop?category=game'
    },
    {
      iconPath: '/image/gnb-hot.png',
      title: '핫딜상품',
      link: '/shop?category=hot'
    },
  ]

  // MenuList
  const menuList = [
    {
      title: '회원가입',
      link: '/join'
    },
    {
      title: '로그인',
      link: '/login'
    },
    {
      title: '회사소개',
      link: '/about'
    },
  ]
  const userMenuList = [
    {
      title: '마이페이지',
      link: '/my/order?page=0'
    },
    {
      title: '로그아웃',
      handleClick: handleLogout
    },
    {
      title: '회사소개',
      link: '/about'
    },
  ]

  // 상단 카테고리 리스트 가져오기
  const {getCategoryList} = CategoryAction()
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10
  })
  const [categoryList, setCategoryList] = useState<CategoryInterface[]>([])
  useEffect(() => {
    getCategoryList(pagination)
    .then((res) => {
      setCategoryList(res.data)
    })
  }, [])

  // 카테고리 서비스 준비중
  const setAlert = useSetRecoilState(alertAtom)
  const handleCategoryClick = () => {
    setAlert({
      title: '카테고리',
      type: 'alert',
      message: '서비스 준비중 입니다.',
      isShow: true
    })
  }

  return (
    <HeaderStyle>
      <div className='container'>
        <h1 id='logo'>
          <Link to={'/'}>엠케이몰</Link>
        </h1>
        <div id='gnb'>
          <ul>
            {
              categoryList.map((item, index) => {
                return (
                  <li key={index}>
                    {
                      item.count > 0 ? (
                        <Link to={`/shop?category=${item.id}`}>
                          <img src={`/image/gnb-${item.code}.png`} alt={item.name} />
                        </Link>
                      ) : (
                        <button 
                          type="button"
                          onClick={handleCategoryClick}
                        >
                          <img src={`/image/gnb-${item.code}.png`} alt={item.name} />
                        </button>
                      )
                    }
                  </li>
                )
              })
            }
          </ul>
        </div>
        <div className='menu'>
          <ul>
            {
              (auth.token ?  userMenuList : menuList).map((item: MenuListInterface, index) => {
                return (
                  <li key={index}>
                    {
                      item.link ? (
                        <Link to={item.link}>
                          {item.title}
                        </Link>
                      ) : (
                        <button
                          type='button'
                          onClick={item.handleClick}
                        >
                          {item.title}
                        </button>
                      )
                    }
                  </li>
                )
              })
            }
          </ul>
          <Link to={'/contact'}>
            대량거래/체인점문의
          </Link>
        </div>
      </div>
    </HeaderStyle>
  )
}

export default Header
